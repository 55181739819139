import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

export const query = graphql`
  query {
    wpPage(id: {eq: "cG9zdDoyMg=="}) {
      id
      title
      content
    }
  }
`;

const Contact = ({ data }) => {
  const page = data.wpPage;

  const [formState, setFormState] = React.useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [formSubmitted, setFormSubmitted] = React.useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setFormState({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  const handleSubmit = (e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formState }),
    })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((error) => console.error(error));

    e.preventDefault();
    resetForm();
    e.target.reset();
  };

  return (
    <Layout>
      <h1 dangerouslySetInnerHTML={{ __html: page.title }} />
      <div dangerouslySetInnerHTML={{ __html: page.content }} />

      <h2 className="form-title">
        Pro více informací využijte kontaktní formulář
      </h2>
      {formSubmitted ? <h3>Děkujeme za Vaši zprávu!</h3> : null}

      <form
        onSubmit={handleSubmit}
        id="contact"
        method="post"
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />

        <fieldset>
          <input
            placeholder="Jméno Příjmení*"
            type="text"
            required
            name="name"
            onChange={handleChange}
            value={formState.name}
          />
        </fieldset>
        <fieldset>
          <input
            placeholder="Email*"
            name="email"
            type="email"
            required
            onChange={handleChange}
            value={formState.email}
          />
        </fieldset>
        <fieldset>
          <input
            placeholder="Telefon"
            name="phone"
            type="tel"
            required
            onChange={handleChange}
            value={formState.phone}
          />
        </fieldset>
        <fieldset>
          <textarea
            placeholder="Zpráva*"
            name="message"
            required
            onChange={handleChange}
            value={formState.message}
          ></textarea>
        </fieldset>
        <fieldset>
          <button
            name="submit"
            type="submit"
            id="contact-submit"
            data-submit="...Sending"
          >
            Odeslat
          </button>
        </fieldset>
      </form>

      <iframe
        src="https://www.google.com/maps/d/embed?mid=1L0-mRTRwIRThCiABiH0Yk5BqVTJUt7CW&hl=cs"
        width="100%"
        height="480"
        title="Kudy se k nám dostanete"
      ></iframe>
    </Layout>
  );
};

export default Contact;
